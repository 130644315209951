import React from "react";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faFolder,
  faArrowLeft,
  faArrowRight,
  faArrowRotateRight,
  faLock,
  faGlobeAmericas,
  faXmark,
  faEllipsisV,
  faDownLeftAndUpRightToCenter,
  faPlus,
} from "@fortawesome/free-solid-svg-icons";
import { faChrome } from "@fortawesome/free-brands-svg-icons";

class ChromeWindow extends React.Component {
  constructor(props) {
    super(props);
    this.state = {
      activeTab: 0,
    };
  }

  render() {
    return (
      <div className="window">
        <div className="menu-bar">
          <div className="buttons">
            <div>
              <FontAwesomeIcon icon={faXmark} />
            </div>
            <div>
              <div className="horizontal"></div>
            </div>
            <div>
              <FontAwesomeIcon icon={faDownLeftAndUpRightToCenter} />
            </div>
          </div>
          <div className="tabs">
            {this.props.activeTabs.map((tab, i) => {
              return (
                <div key={`tab-${i}`}>
                  <FontAwesomeIcon
                    icon={tab === "New tab" ? faChrome : faGlobeAmericas}
                  />
                  <div className="select-none">{tab}</div>
                  <FontAwesomeIcon icon={faXmark} className="cursor-pointer" />
                </div>
              );
            })}
            <FontAwesomeIcon icon={faPlus} />
          </div>
        </div>

        <div
          className={
            this.props.bookmarks
              ? "url-bar border-b sm:border-b-0 border-b-neutral-200"
              : "url-bar border-b border-b-neutral-200"
          }
        >
          <FontAwesomeIcon icon={faArrowLeft} className="disabled" />
          <FontAwesomeIcon icon={faArrowRight} className="disabled" />
          <FontAwesomeIcon icon={faArrowRotateRight} />
          <div className="text-black">
            <FontAwesomeIcon icon={faLock} />
            <div>{this.props.link}</div>
          </div>
          <FontAwesomeIcon icon={faEllipsisV} className="w-4 -ml-2" />
        </div>

        {this.props.bookmarks ? (
          <div className="bookmarks">
            {this.props.bookmarks.map((bookmark, i) => (
              <div key={`bookmark-${i}`}>
                <FontAwesomeIcon icon={bookmark.icon ?? faFolder} />
                <div>{bookmark.text}</div>
              </div>
            ))}
          </div>
        ) : null}

        <div className="content">{this.props.children}</div>
      </div>
    );
  }
}

export default ChromeWindow;
