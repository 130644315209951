import React from "react";

class Typewriter extends React.Component {
  constructor(props) {
    super(props);
    this.keyDown = this.keyDown.bind(this);

    this.state = {
      text: props.text,
      typingSpeed: props.typingSpeed ?? 130,
      output: '',
      outputDone: false,
      blinking: false
    }
  }

  keyDown(event) {
    if (event.key === "Backspace") {
      return this.setState(state => ({
        ...state,
        output: state.output.substring(0, state.output.length - 1)
      }));
    }

    if (this.state.outputDone && event.key.length === 1) {
      this.setState(state => ({
        ...state,
        output: state.output + event.key
      }));
    }
  }

  blinking() {
    this.setState(state => ({
      ...state,
      blinking: !state.blinking
    }));
  }

  tick() {
    if (this.state.outputDone) {
      this.setState(state => ({
        ...state,
        blinking: !state.blinking
      }));
    }
    else {
      if (this.state.text === this.state.output) {
        clearInterval(this.interval);
        this.interval = setInterval(() => this.blinking(), 600);

        return this.setState(state => ({
          ...state,
          outputDone: true,
          blinking: !state.blinking
        }));
      }

      this.setState(state => ({
        ...state,
        output: state.text.substring(0, state.output.length + 1)
      }));
    }
  }

  componentDidMount() {
    document.addEventListener("keydown", this.keyDown, false);
    this.interval = setInterval(() => this.tick(), 130);
  }

  componentWillUnmount() {
    document.removeEventListener("keydown", this.keyDown, false);
    clearInterval(this.interval);
  }

  render() {
    return (
      <div className={`typewriter ${!this.state.output && 'opacity-20'}`}>
        {this.state.output ? this.state.output : 'awaiting input .. '}<span className={this.state.blinking ? 'hidden' : ''}>|</span>
      </div>
    )
  }
}

export default Typewriter;
