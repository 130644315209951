import React from "react";
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { ReactSVG } from 'react-svg';

class Languages extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      hovered: false,
      currentVisible: 0
    }
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      this.setState(state => ({
        ...state,
        currentVisible: state.currentVisible === (this.props.items.length - 1) ? 0 : state.currentVisible + 1
      }))
    }, 1500);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    return (
      <div
        onMouseEnter={() => this.setState(state => ({
          ...state,
          hovered: true
        }))}
        onMouseLeave={() => this.setState(state => ({
          ...state,
          hovered: false
        }))}
        className="flex text-5xl gap-x-8 gap-y-4 items-center justify-center child-hover:opacity-100 child:transition-opacity child:duration-500 flex-wrap"
      >
        {
          this.props.items.map((item, i) => {
            const className = this.state.currentVisible === i && !this.state.hovered ? 'opacity-100' : 'opacity-70';
            const onLeave = () => {
              this.setState(state => ({
                ...state,
                currentVisible: i
              }))
            };

            if (typeof item === 'string') {
              return <ReactSVG key={`languages-${i}`} svg="public/tailwindcss.svg" className={className} onMouseLeave={onLeave} />
            }
            else {
              return <FontAwesomeIcon key={`languages-${i}`} icon={item} className={className} onMouseLeave={onLeave} />
            }
          })
        }
      </div>
    )
  }
}

export default Languages;
