import React from "react";

const characters = "#%&=?$@+-<>/\\()[]!;:.,_";
const getRandomCharacter = (currentCharacter) => {
  let character = null;
  while (!character || character === currentCharacter) {
    character = characters.charAt(Math.floor(Math.random() * characters.length));
  }

  return characters.charAt(Math.floor(Math.random() * characters.length));
}

class Binary extends React.Component {
  constructor(props) {
    super(props);

    this.keyCount = 0;
    this.getKey = this.getKey.bind(this);

    this.state = {
      elements: []
    }
  }

  getKey() {
    return this.keyCount++;
  }

  tick() {
    const parent = document.querySelector('#binaryParent');
    const maxWidth = parent.clientWidth;
    const maxHeight = parent.clientHeight;

    this.setState(state => ({
      ...state,
      elements: [
        ...state.elements,
        <Character key={this.getKey()} style={{ top: Math.floor(maxHeight * Math.random()) - 100, left: Math.floor(maxWidth * Math.random()) }} />
      ]
    }));

    setTimeout(() => {
      this.setState(state => ({
        ...state,
        elements: state.elements.slice(1)
      }))
    }, 3000);
  }

  componentDidMount() {
    const parent = document.querySelector('#binaryParent');
    const maxWidth = parent.clientWidth;
    const maxHeight = parent.clientHeight;

    this.interval = setInterval(() => this.tick(), Math.max(5000 / (maxWidth * maxHeight / 1800), 20));
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    return (
      <div className="typewriter text-3xl text whitespace-pre-wrap break-all opacity-10">
        <div className="">
          {
            this.state.elements.map(element => {
              return element;
            })
          }
        </div>
      </div>
    )
  }
}

class Character extends React.Component {
  constructor(props) {
    super(props);

    this.state = {
      character: getRandomCharacter(),
      dropping: false,
      style: props.style
    }
  }

  componentDidMount() {
    this.interval = setInterval(() => {
      this.setState(state => ({
        ...state,
        character: getRandomCharacter(state.character)
      }));
    }, 500);

    setTimeout(() => {
      this.setState(state => ({
        ...state,
        dropping: true,
        style: {
          ...state.style,
          top: state.style.top + 100
        }
      }));
    }, 10);
  }

  componentWillUnmount() {
    clearInterval(this.interval);
  }

  render() {
    return (
      <div className={`dropping-binary ${this.state.dropping ? 'opacity-0' : 'opacity-100'}`} style={this.state.style}>
        {this.state.character}
      </div>
    )
  }
}

export default Binary;
