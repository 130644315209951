import "./App.css";
import Binary from "./components/Binary";
import Typewriter from "./components/Typewriter";
import ChromeWindow from "./components/ChromeWindow";
import { faCode, faHeart, faEnvelope } from "@fortawesome/free-solid-svg-icons";
import { FontAwesomeIcon } from "@fortawesome/react-fontawesome";
import {
  faNode,
  faPhp,
  faCss3,
  faReact,
  faJsSquare,
  faHtml5,
  faGithub,
  faLinkedin,
} from "@fortawesome/free-brands-svg-icons";
import Languages from "./components/Languages";

function App() {
  // const renderLanguage = (language) => {
  //   return (
  //     <div key={language.text}>
  //       <code>{language.text}</code>
  //       {language.children && (
  //         <children>{language.children.map(renderLanguage)}</children>
  //       )}
  //     </div>
  //   );
  // };

  // const languages = [
  //   {
  //     text: "Full-stack developer",
  //     children: [
  //       {
  //         text: "Frontend",
  //         children: [
  //           {
  //             text: "JavaScript",
  //           },

  //           {
  //             text: "TailwindCSS",
  //           },

  //           {
  //             text: "React",
  //           },
  //         ],
  //       },

  //       {
  //         text: "Backend",
  //         children: [
  //           {
  //             text: "PHP",
  //           },

  //           {
  //             text: "Node.js",
  //           },
  //         ],
  //       },

  //       {
  //         text: "Databases",
  //         children: [
  //           {
  //             text: "MySQL",
  //           },

  //           {
  //             text: "MongoDB",
  //           },
  //         ],
  //       },
  //     ],
  //   },
  // ];

  return (
    <main className="flex flex-col">
      <nav className="flex justify-between p-4 items-center break-all bg-gradient-to-r from-white/20 to-transparent">
        <div className="text-3xl">
          <Typewriter text="steffen.codes" />
        </div>
      </nav>

      <section className="grow flex flex-col gap-y-10 items-center lg:px-20 relative max-w-screen-xl mx-auto">
        <div
          className="absolute h-full w-full -mx-10 lg:-mx-20 overflow-hidden pointer-events-none"
          id="binaryParent"
        >
          <Binary />
        </div>

        <div className="grow relative flex flex-col gap-4 items-center md:flex-row-reverse md:justify-between md:gap-x-48">
          <div className="image absolute z-20 text-6xl md:hidden">👋</div>
          <div className="relative">
            <div className="image rounded-full border-4 relative overflow-hidden z-10">
              <img
                src={`${process.env.PUBLIC_URL}/me.jpg`}
                className="object-cover"
                alt="Steffen Andreas Kloster"
              />
            </div>
            <div className="absolute animate-ping-slow bg-purple-800 rounded-full w-2/3 h-2/3 top-16 left-16 md:block hidden"></div>
          </div>

          <div className="flex gap-x-4 md:items-center">
            <div className="text-5xl hidden md:block wave-animation">👋</div>
            <div>
              <div className="font-medium text-base lg:text-lg text-center md:text-left">
                Hiya! Thanks for stopping by, I'm
              </div>
              <h1 className="text-center md:text-left">
                Steffen Andreas{" "}
                <span className="text-base font-normal block text-center md:text-left md:inline">
                  (he/him)
                </span>
              </h1>
            </div>
          </div>
        </div>

        <div className="bg-white/10 w-full p-10 flex flex-col gap-y-4">
          <h1 className="lowercase">About me</h1>
          <p>
            I'm a full-stack web developer based in Copenhagen, who really{" "}
            <b>loves</b> to code. I'm currently studying Multimedia Design,
            while working part-time as a webdeveloper and also doing freelance
            work through my company{" "}
            <a href="https://websta.dk/" rel="noreferrer" target="_blank">
              Websta
            </a>
            . I primarily work in Next.js, React, Tailwind and MongoDB for
            multipage large websites, but I am also really proficient with the
            LEMP-stack. I am experienced in Docker and Git.
          </p>
        </div>
      </section>

      <section className="bg-black/90 text-white">
        <div className="max-w-screen-lg mx-auto flex flex-col gap-y-8">
          <h1 className="flex gap-x-4 items-center">
            <img
              alt="Pixel art of an onyx"
              src={`${process.env.PUBLIC_URL}/onyx.svg`}
              className="h-16"
            />
            <div>Onyx CMS</div>
          </h1>

          <p>
            A fully featured CMS made for nightclubs and venues, with features
            such as events, complete user system, guest list with multiple
            roles/lists and much more. Onyx automates creating users for your
            DJ's, and with only a link, are they able to login and add people to
            their guestlist. It is fully responsive and developed in
            Next.js/React and MongoDB/Mongoose.
          </p>

          <p>
            Onyx CMS is a commercial product and is therefore closed source. You
            can however view a demo here: <i>coming soon</i>.
          </p>

          <ChromeWindow
            link="https://onyx.websta.dk/"
            activeTabs={["Onyx CMS"]}
            bookmarks={[
              {
                icon: faCode,
                text: "Next.js/React, MongoDB, TailwindCSS",
              },
            ]}
          >
            <div className="-m-4">
              <img
                src={`${process.env.PUBLIC_URL}/onyx.jpeg`}
                className="w-full"
                alt="Screenshot of Onyx CMS"
              />
            </div>
          </ChromeWindow>
        </div>
      </section>

      <section>
        <div className="max-w-screen-lg mx-auto flex flex-col gap-y-8">
          <h1>Companies I've worked with</h1>
          <ul className="flex gap-x-4 flex-wrap text-lg">
            {[
              ["Telanco", "https://telanco.dk/"],
              ["MinEjendom", "https://minejendom.dk/"],
              ["SCHØDT", "https://schodt.dk/"],
              ["Flatr", "https://flatr.dk/"],
              ["Vedligeholdelsesplan.dk", "https://vedligeholdelsesplan.dk/"],
              ["Ved Siden Af", "http://vedsidenaf.com/"],
              ["Queer Music Agency", "https://queermusic.agency/"],
              ["Den Anden Side", "https://denandenside.com/"],
              ["Drag Booking Agency", "https://dragbooking.agency/"],
              ["GoMaps", "https://gomaps.dk/"],
              ["Websta", "https://websta.dk/"],
            ].map((company, i) => (
              <li key={`company-${i}`}>
                <a href={company[1]}>{company[0]}</a>
              </li>
            ))}
          </ul>
        </div>
      </section>

      <section className="bg-purple-100 text-black relative overflow-hidden">
        <div className="max-w-screen-lg mx-auto flex flex-col gap-y-8">
          <h1 className="text-center">Lets get in touch!</h1>
          <div className="flex gap-x-4 items-end -mt-4 justify-center">
            <ul>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://github.com/steffenkloster"
                >
                  <FontAwesomeIcon icon={faGithub} /> Github
                </a>
              </li>
              <li>
                <a
                  target="_blank"
                  rel="noreferrer"
                  href="https://www.linkedin.com/in/steffen-andreas-kloster-35b6a6143/"
                >
                  <FontAwesomeIcon icon={faLinkedin} /> LinkedIn
                </a>
              </li>
              <li>
                <a href="mailto:steffenakloster@gmail.com">
                  <FontAwesomeIcon icon={faEnvelope} />{" "}
                  steffenakloster@gmail.com
                </a>
              </li>
            </ul>

            <img
              src={`${process.env.PUBLIC_URL}/pooh.gif`}
              alt="Animated Winnie the Pooh dancing"
            />
          </div>
        </div>

        <img
          className="absolute rotate-45 opacity-10 w-[32rem] mail-animation"
          src={`${process.env.PUBLIC_URL}/mail.svg`}
          alt=""
        />
      </section>

      <section className="bg-purple-700 text-white">
        <div className="max-w-screen-xl mx-auto flex flex-col gap-y-8">
          <h1 className="text-3xl text-center">
            Languages and frameworks{" "}
            <span className="whitespace-nowrap">
              I{" "}
              <FontAwesomeIcon
                className="text-red-600 animate-heartbeat"
                icon={faHeart}
              />{" "}
              to
            </span>{" "}
            work with
          </h1>

          <Languages
            items={[faJsSquare, faNode, faPhp, faReact, faCss3, faHtml5]}
          />
        </div>
      </section>

      {/* <section className='bg-white text-black'>
        <div className='max-w-screen-xl mx-auto flex flex-col items-center gap-y-8'>
          <div className='tree'>
            {
              languages.map(renderLanguage)
            }
          </div>
        </div>
      </section> */}

      <footer className="bg-purple-800 py-2">
        <div className="max-w-screen-lg mx-auto text-center font-medium">
          Built with 🏳️‍🌈 and React.js + Tailwind CSS
        </div>
      </footer>
    </main>
  );
}

export default App;
